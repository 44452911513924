import React from "react"
import Headings from "./headings"

const CommentsBubbles = () => {
  return (
    <div className="uk-section uk-section uk-padding-remove uk-margin-small-top uk-margin-medium-bottom">
      <div className="uk-container ">
        <Headings
          title="Our Community"
          description="Please see some of the comments and testimonials from our social media platforms"
        />
        <div
          uk-grid="true"
          className="uk-child-width-1-1 tm-comment-list uk-margin-small-top"
        >
          <div>
            <div uk-grid="true" className="uk-grid-collapse">
              <div className="uk-width-1-1@s uk-width-5-6@m">
                <div className="uk-comment uk-comment-primary uk-border-rounded">
                  <p>
                    On Donating towards the land - One of, if not, the best
                    moments of our lives, we cried like babies that night
                    knowing what we were going to do. I'm forever grateful to
                    you for helping us to do something truly meaningful for
                    myself and Jez to look back on forever
                  </p>
                </div>
              </div>
              <div className="uk-width-1-6@m uk-flex-first@m">
                <div className="uk-comment-avatar uk-flex uk-flex-left">
                  <div className="uk-width-small uk-text-center">
                    <span uk-icon="icon: user; ratio: 2.5"></span>
                    <h6 className="uk-margin-top">
                      Michaela and Jez Winkfield
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div uk-grid="true" className="uk-grid-collapse">
              <div className="uk-width-5-6@m">
                <div className="uk-comment uk-comment-primary uk-border-rounded">
                  <p>
                    Chulie… you are an amazing woman, not only do you rescue but
                    you also find the time to update people on the dogs in your
                    care. Not many do that.
                  </p>
                </div>
              </div>
              <div className="uk-width-1-6@m">
                <div className="uk-comment-avatar uk-flex uk-flex-left@m uk-flex-right">
                  <div className="uk-width-small uk-text-center">
                    <span uk-icon="icon: user; ratio: 2.5"></span>
                    <h6 className="uk-margin-top">Susan Lister</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div uk-grid="true" className="uk-grid-collapse">
              <div className="uk-width-5-6@m">
                <div className="uk-comment uk-comment-primary uk-border-rounded">
                  <p>
                    What Chulie does is amazing. She is very transparent in what
                    she does and that is refreshing because as donors, we need
                    to know where it goes.
                  </p>
                </div>
              </div>
              <div className="uk-width-1-6@m uk-flex-first@m">
                <div className="uk-comment-avatar uk-flex uk-flex-left">
                  <div className="uk-width-small uk-text-center">
                    <span uk-icon="icon: user; ratio: 2.5"></span>
                    <h6 className="uk-margin-top">Maria Madelena Gouveia</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              uk-grid="true"
              className="uk-grid-collapse"
              style={{ backgroungColor: "red" }}
            >
              <div className="uk-width-5-6@m">
                <div className="uk-comment uk-comment-primary uk-border-rounded">
                  <p>
                    What would these poor little dogs do without you and
                    everyone else who helps you. The care, time and love you
                    give them is beyond amazing.
                  </p>
                </div>
              </div>
              <div className="uk-width-1-6@m">
                <div className="uk-comment-avatar uk-flex uk-flex-left@m uk-flex-right">
                  <div className="uk-width-small uk-text-center">
                    <span uk-icon="icon: user; ratio: 2.5"></span>
                    <h6 className="uk-margin-top">Lynne Jackson</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommentsBubbles
