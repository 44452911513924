import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Headings from "../components/headings"
import Hero from "../components/hero"
import FeaturedArticle from "../components/featured-article"
import CommentsBubbles from "../components/comments-bubbles"
import Champion from "../components/champion"
import TestimonialSliderBackgroundImage from "../components/testimonial-slider-background-image"
import BackgroundImage from "../images/slider-image-v5.jpg"
import CtaSlider from "../components/cta-slider"
import ShareButton from "../components/share-buttons"
import { Iframe } from "../components/iframe"

const TestimonialsPage = pageContext => {
  //console.log("TestimonialsPage - pageContext: ", pageContext)
  const { strapiTestimonial } = useStaticQuery(graphql`
    query {
      strapiTestimonial {
        hero {
          ...StrapiHero
        }
        seo {
          ...StrapiSeo
        }
        featuredArticle {
          ...StrapiFeaturedArticle
        }
        callToAction {
          ...StrapiCallToAction
        }
        headings {
          title
          description
        }
        testimonialSection {
          ...TestimonialCards
        }
        video {
          headings {
            title
            description
          }
          src
          title
          width
          height
        }
      }
    }
  `)

  const {
    hero,
    seo,
    headings,
    featuredArticle,
    callToAction,
    testimonialSection,
    video,
  } = strapiTestimonial

  const { testimonial_cards } = testimonialSection

  const callToActionSections = callToAction.call_to_action_sections

  const structuredData = seo.structuredData

  const quote = "Please share our testimonials"
  const hashtag = "#SaveTheStreetPooches"

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <Headings title={headings.title} description={headings.description} />

      <Champion />

      <ShareButton      
        description={seo.metaDescription}
        url={pageContext.location.href}
        quote={quote}
        hashtag={hashtag}
        seo={seo}
      />

      <TestimonialSliderBackgroundImage
        BackgroundImage={BackgroundImage}
        testimonialCards={testimonial_cards}
      />

      <CommentsBubbles />

      <Iframe video={video} />

      <FeaturedArticle 
        featuredArticle={featuredArticle}
        title={headings.title} 
        description={headings.description}
        bg="uk-background-default"
      />

      <CtaSlider 
        title={callToAction.title} 
        description={callToAction.description} 
        callToActionSections={callToActionSections} 
        bg="uk-background-default"
      />
    </>
  )
}

export default TestimonialsPage
