import React from "react"
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share"

const ShareButtons = ({ url, seo, quote, hashtag }) => {
  //console.log("Seo props: ", seo)

  const { metaTitle, metaDescription } = seo

  /* console.log(
    "Share button props: ",
    url,
    quote,
    hashtag,
    metaTitle,
    metaDescription
  ) */

  return (
    <div className="uk-section uk-section uk-padding-remove uk-margin-medium-bottom">
      <div className="uk-container  uk-flex uk-flex-center">
        <span>
          <h4
            className="uk-margin-remove uk-text-bold"
            style={{
              color: "#617F7F",
            }}
          >
            {quote}
          </h4>
        </span>
      </div>
      <div className="uk-container uk-flex uk-flex-center uk-margin">
        <FacebookShareButton
          url={url}
          quote={metaDescription}
          hashtag={hashtag}
          className="uk-margin-small-right"
        >
          <FacebookIcon size={42} round={true} />
        </FacebookShareButton>

        <WhatsappShareButton
          url={url}
          title={metaTitle}
          separator=":: "
          className="uk-margin-small-right"
        >
          <WhatsappIcon size={42} round={true} />
        </WhatsappShareButton>

        <TwitterShareButton
          url={url}
          title={metaTitle}
          hashtag={hashtag}
          className="uk-margin-small-right"
        >
          <TwitterIcon size={42} round={true} />
        </TwitterShareButton>

        <LinkedinShareButton
          url={url}
          title={metaTitle}
          summary={metaDescription}
          source="Save the Street Pooches"
          className="uk-margin-small-right"
        >
          <LinkedinIcon size={42} round={true} />
        </LinkedinShareButton>

        <EmailShareButton
          url={url}
          subject={metaTitle}
          body={metaDescription}
          separator=": "
          className="uk-margin-small-right"
        >
          <EmailIcon size={42} round={true} />
        </EmailShareButton>
      </div>
    </div>
  )
}

export default ShareButtons
