import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
//import Headings from "../components/headings"

const Champion = () => {
  return (
    <div className="uk-section uk-padding-remove uk-margin-small-top uk-margin-medium-bottom">
      <div className="uk-container">
        {/* <Headings
          title="Testimonials"
          description="A truly kind, wonderful, selfless, special human being who just refuses to walk by, and do nothing."
        /> */}
        <div
          className="uk-grid-collapse uk-child-width-1-2@m uk-grid-match uk-flex-middle uk-light"
          data-uk-grid="true"
          style={{ backgroundColor: "#617F7F" }}
        >
          <div className="uk-flex-last@m">
            <div>
              <StaticImage
                src="../images/chulie-story-v6.jpg"
                alt="Chulie Perera"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                title="Chulie Perera"
                aspectRatio={1.0}
              />
            </div>
          </div>
          <div>
            <div className="uk-padding">
              <h2 className="uk-h1 uk-card-title">Chulie Perera</h2>
              <p>
                Chulie is one of the most committed people I have ever met.
                Always against great adversity her determination to help these
                dogs carries her through. She is kind, compassionate,
                knowledgeable and always great fun. I am truly in awe of all she
                has achieved so far. "Allison Fletcher"
              </p>
              <p>
                Chulie is wholeheartedly devoted to her purpose and has built
                her wonderful organisation with courage and resilience. Her
                thoughtful and non-judgemental character is coupled with her
                patience and determination. I hold Chulie in very high esteem,
                not only as a friend but as a sincerely genuine and committed
                animal rescuer. "Susan Wells"
              </p>
              <Link
                to="/article/a-day-with-chulie"
                className="hover-underline-animation-white"
                style={{ color: "#FFFFFF", textDecoration: "none" }}
              >
                A day with Chulie Perera
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Champion
